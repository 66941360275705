import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useEffect, useRef, useState } from 'react';
import { getElements as getInterveners } from 'store/intervenersSlice';
import { formatFilter, LIMIT } from './baseFilters.js';
import MenuItem from 'components/menu/MenuItem.js';
import Menu from 'components/menu/Menu.js';
import { Button, Checkbox, InputBase } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon.js';
import { useConfiguration } from 'hooks/useConfiguration.js';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import useNotifications from 'hooks/useNotifications.js';
import TicketExportsNew from 'entities/Ticket/TicketExportsNew.js';
import { useAuth } from 'hooks/useAuth.js';
import { useSelector } from 'react-redux';
import { ticketsList, moreTickets, syncTicketsList, getBoomarksList } from 'store/ticketsListSlice.js';
import {
  getAssociatedFiltersFromBookmarks,
  transformAlertExceeded,
  transformCustomFields,
  transformDates,
  transformHasContractor,
  transformHasManagers,
  transformHasNotes,
  transformHasSkills,
  transformLate,
  transformMyInterventions,
  transformReports,
  transformSpecialType,
  transformStatus,
  transformValidedPeriod
} from 'components/filters/specificFilters/transformFilters.js';
import { statesClosed, statesOngoing } from './utils.js';
import axios from 'axios';
import { apiBaseURL } from 'index.js';
import { useRole } from 'hooks/useRole.js';

export const syncTickets = ({ dispatch }) => {
  dispatch(syncTicketsList);
};

export const fetchTickets = ({ auth, dispatch, setSkip, skip, listId, notify }) => {
  return async (filters, loadMore = false) => {
    const nextSkip = loadMore ? skip + LIMIT : 0;
    if (loadMore) setSkip(nextSkip); // Update skip state only when loading more

    const formattedFilters = formatFilter(filters, auth, nextSkip, LIMIT);

    try {
      const action = loadMore ? moreTickets : ticketsList;
      dispatch(action, { filters: formattedFilters }, {}, { listId });
    } catch (error) {
      notify.error();
      console.error(error);
    }
  };
};

export const useFilteredBookmarks = (calendar, appliedFilters, setBookmarkState, initialBookmarks) => {
  const { dispatch } = useAsyncDispatch();
  const auth = useAuth();
  const previousFiltersRef = useRef(null); // Garde la trace des filtres précédents
  const isFirstRun = useRef(true); // Indique si c'est la première exécution

  useEffect(() => {
    const hasFiltersChanged = () => {
      const { bookmarks, ...currentFilters } = appliedFilters;
      const previousFilters = previousFiltersRef.current;

      // Si c'est la première exécution, ou si les filtres ont changé (hors `bookmarks`)
      return (
        (isFirstRun.current || JSON.stringify(currentFilters) !== JSON.stringify(previousFilters)) && !bookmarks?.length
      );
    };

    if (hasFiltersChanged()) {
      // Met à jour la référence des filtres et désactive le flag de première exécution
      previousFiltersRef.current = { ...appliedFilters, bookmarks: undefined };
      isFirstRun.current = false;

      const fetchBookmarks = async () => {
        setBookmarkState({ bookmarks: initialBookmarks, isLoading: true });
        const requests = getAssociatedFiltersFromBookmarks(
          initialBookmarks.map((bookmark) => bookmark.key),
          appliedFilters,
          auth
        );

        if (!calendar) {
          dispatch(
            getBoomarksList,
            { requests },
            {
              onSuccess: (data) => {
                const bookmarks = data
                  .filter((bookmark) => bookmark.val)
                  .map((bookmark) => {
                    const initialBookmark = initialBookmarks.find((bm) => bm.key === bookmark.key);
                    return {
                      ...bookmark,
                      color: initialBookmark?.color,
                      label: initialBookmark?.label
                    };
                  });

                setBookmarkState({
                  bookmarks,
                  isLoading: false
                });
              },
              onError: () => {
                setBookmarkState({
                  bookmarks: [],
                  isLoading: false
                });
              }
            }
          );
        } else {
          dispatch(getInterveners, {
            limit: { startIndex: 0, stopIndex: LIMIT },
            filters: {
              tab: { collaborator: true, mine: false, public: false },
              deleted: false,
              sort: { sort: 1, value: 'companyName' }
            }
          }).then(({ data }) => {
            setBookmarkState({
              bookmarks: [
                initialBookmarks[0],
                ...data.elements?.slice(0, 9).map(({ _id, color, firstName, lastName }) => ({
                  label: `${firstName} ${lastName ? lastName[0].toUpperCase() + '.' : ''}`,
                  key: _id,
                  color
                }))
              ],
              isLoading: false
            });
          });
        }
      };

      fetchBookmarks();
    }
  }, [appliedFilters, calendar, auth, setBookmarkState, initialBookmarks, dispatch]);
};

const getDefaultSet = (appliedFilters) => ({
  title: 'defaultFilterSet',
  filters: normalizeFilters({ filters: appliedFilters }),
  _id: '123'
});

export const createFilterSetsManager = (appliedFilters, showCustomFilters, entity) => {
  const last_used_filter_set = localStorage.getItem(`last_used_filter_set_${entity}`);
  if (last_used_filter_set && !appliedFilters.deleted && showCustomFilters) return JSON.parse(last_used_filter_set);
  else
    return {
      filterSets: [getDefaultSet(appliedFilters)],
      selectedSet: getDefaultSet(appliedFilters),
      orderFilters: Object.keys(appliedFilters)
    };
};

export const generateFilters = (orderFilters, showableFilters) =>
  orderFilters
    .map((path) => showableFilters.find((f) => f.props.path === path))
    .filter(Boolean)
    .map(({ Component, props }) => (
      <Component
        key={props.label}
        {...props}
      />
    ));

export const ticketMenuItems = ({ t, fetchTickets, appliedFilters, setIsTrashOpen, count }) => {
  return [
    <MenuItem
      icon="rotate-right"
      label="refresh"
      onClick={() => fetchTickets(appliedFilters, false)}
    />,
    <Menu
      button={() => (
        <MenuItem
          icon="download"
          label={t('ExportXEls', { count })}
          hasSubMenu
        />
      )}
      items={[<PopoverExportTickets appliedFilters={appliedFilters} />]}
      placement="right-start"
    />,
    <MenuItem
      icon="trash"
      label="trash"
      onClick={() => setIsTrashOpen(true)}
    />
  ];
};

export const PopoverExportTickets = ({ appliedFilters }) => {
  const config = useConfiguration();
  const auth = useAuth();
  const role = useRole();
  const customFields = useSelector((store) => store.fieldSections).ticket;
  const ticketExport = TicketExportsNew(config, customFields?.[0]?.fields, role);
  const [selectedFields, setSelectedFields] = useState(ticketExport.map(({ key }) => key));
  const [isLoading, setIsLoading] = useState({
    csv: false,
    xlsx: false
  });
  const [search, setSearch] = useState('');
  const notify = useNotifications();
  const { t } = useTranslation();

  // const toggleSelectAll = () => {
  //   setSelectedFields(selectedFields.length === ticketExport.length ? [] : ticketExport.map(({ key }) => key));
  // };

  const sortByOriginalOrder = (selected) => ticketExport.map(({ key }) => key).filter((k) => selected.includes(k));

  const handleSelectField = ({ key, checked }) => {
    setSelectedFields((prev) => {
      const newSelected = checked ? [...prev, key] : prev.filter((field) => field !== key);
      return sortByOriginalOrder(newSelected);
    });
  };

  const handleExport = async (format) => {
    setIsLoading((prev) => ({ ...prev, [format]: true }));

    // I put 0 as skip and limit because I don't want to fix limit and skip (in export we want all tickets !).
    const filters = formatFilter(appliedFilters, auth, 0, 0).filter((f) => !['limit', 'skip'].includes(f.key));

    try {
      // Effectuer la requête avec axios
      const response = await axios.post(
        `${apiBaseURL}/ticket-list/export`,
        { filters, fields: selectedFields, format },
        { responseType: 'blob' } // Spécifie que la réponse doit être traitée comme un Blob
      );

      const filename = format === 'xlsx' ? 'tickets.xlsx' : 'tickets.csv';

      // Créer un Blob avec les données renvoyées par le backend
      const fileExport = new Blob([response.data], {
        type:
          format === 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv;charset=UTF-8'
      });

      // Utiliser FileSaver pour déclencher le téléchargement
      saveAs(fileExport, filename);

      // Afficher un message de succès
      notify.success(t('exportSuccessMessage'));
      setIsLoading((prev) => ({ ...prev, [format]: false }));
    } catch (error) {
      console.error('Export failed:', error);
      notify.error();
      setIsLoading((prev) => ({ ...prev, [format]: false }));
    }
  };

  const filteredTickets = ticketExport.filter(({ label }) => t(label).toLowerCase().includes(search.toLowerCase()));

  return (
    <div className="flex flex-col gap-1 p-1">
      {/* <div className="flex gap-1 items-center"> */}
      <InputBase
        className="px-4 py-2"
        placeholder={t('findValue')}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {/* <Button
          className="!text-xs"
          onClick={toggleSelectAll}
          disabled={search.length}
        >
          {selectedFields.length === ticketExport.length ? t('unselectAll') : t('selectAll')}
        </Button>
      </div> */}

      <div className="max-h-[50vh] w-80 overflow-auto">
        {filteredTickets.map(({ label, key }) => {
          const isChecked = selectedFields.includes(key);
          return (
            <div
              key={key}
              className="flex items-center gap-1 px-1 hover:bg-gray-100 rounded-lg cursor-pointer"
              onClick={() => handleSelectField({ key, checked: !isChecked })}
            >
              <Checkbox
                color={'primary'}
                checked={isChecked}
              />
              <span className="text-sm py-1">{t(label)}</span>
            </div>
          );
        })}
      </div>

      <div className="flex gap-2">
        <Button
          variant="outlined"
          onClick={() => handleExport('csv')}
          endIcon={
            isLoading.csv ? (
              <FAIcon
                icon="spinner"
                className="fa-spin"
                collection="fas"
                size="small"
              />
            ) : (
              <FAIcon
                icon="download"
                collection="fas"
                size="small"
                className="text-gray-700"
              />
            )
          }
          disabled={isLoading.csv || !selectedFields.length}
          className="!rounded-lg w-full"
        >
          <div className="flex items-center gap-1 text-xs">CSV</div>
        </Button>
        <Button
          variant="contained"
          onClick={() => handleExport('xlsx')}
          color="primary"
          endIcon={
            isLoading.xlsx ? (
              <FAIcon
                icon="spinner"
                className="fa-spin"
                collection="fas"
                size="small"
              />
            ) : (
              <FAIcon
                icon="download"
                collection="fas"
                size="small"
              />
            )
          }
          disabled={isLoading.xlsx || !selectedFields.length}
          className="!rounded-lg w-full"
        >
          <div className="flex items-center gap-1 text-xs">Excel</div>
        </Button>
      </div>
    </div>
  );
};

export const trashMenuItems = ({ t, fetchTickets, appliedFilters, count }) => {
  return [
    <MenuItem
      icon="rotate-right"
      label="refresh"
      onClick={() => fetchTickets(appliedFilters)}
    />,
    <Menu
      button={() => (
        <MenuItem
          icon="download"
          label={t('ExportXEls', { count })}
          hasSubMenu
        />
      )}
      items={[<PopoverExportTickets appliedFilters={appliedFilters} />]}
      placement="left-start"
    />
  ];
};

const getDefaultFilterEntry = (key, value) => ({
  key,
  val:
    Array.isArray(value) && value.every((item) => typeof item === 'object' && item?._id)
      ? value.map((item) => item._id)
      : value,
  op: key === 'search' ? 'fuzzy' : Array.isArray(value) ? 'in' : typeof value === 'object' ? 'object' : 'equals'
});

// function which transform { key: value, ... } to [{ key: "key", value: "value", op:"op"}, ...] <-- for UI
export const normalizeFilters = ({ filters }) => {
  if (Array.isArray(filters)) return filters;

  return Object.entries(filters).map(([key, value]) => getDefaultFilterEntry(key, value));
};

// function which transform { key: value, ... } to [{ key: "key", value: "value", op:"op"}, ...] <-- for API
export const prepareFiltersForApi = ({ filters, auth }) => {
  if (Array.isArray(filters)) return filters;

  // TODO: Combine `clients` and `contractors` into `companies`
  if (filters.clients) filters.companies = filters.clients || [];

  // Merge domains and subDomains
  if (filters.subDomains || filters.domains) {
    filters.domains = [...(filters.subDomains || []), ...(filters.domains || [])];

    if (filters.domains.includes('without-domain')) {
      filters.has_domains = false;
      filters.domains = null;
    }
  }

  const specialHandlers = {
    quotes: transformSpecialType,
    invoices: transformSpecialType,
    reports: transformReports,
    dates: transformDates,
    ongoing: transformStatus(statesOngoing),
    closed: transformStatus(statesClosed),
    validatedPeriod: transformValidedPeriod,
    customFields: transformCustomFields,
    has_contractor: transformHasContractor,
    has_skills: transformHasSkills,
    has_managers: transformHasManagers,
    has_notes: transformHasNotes,
    alertExceeded: transformAlertExceeded,
    myInterventions: transformMyInterventions,
    late: transformLate
  };

  const result = Object.entries(filters)
    .filter(([key, value]) => isValidValue(value) && key !== 'clients' && key !== 'subDomains')
    .flatMap(([key, value]) =>
      specialHandlers[key] ? specialHandlers[key](key, value, auth) : [getDefaultFilterEntry(key, value)]
    );

  // Merge filters with `op: 'in'` for `status` and `managers`
  const mergeFiltersByKey = (key) =>
    result
      .filter((filter) => filter.key === key && filter.op === 'in')
      .reduce(
        (acc, filter) => {
          acc.val.push(...filter.val);
          return acc;
        },
        { key, val: [], op: 'in' }
      );

  const mergedStatusFilter = mergeFiltersByKey('status');
  const mergedManagersFilter = mergeFiltersByKey('managers');

  // Remove duplicate values from the merged filters
  mergedStatusFilter.val = [...new Set(mergedStatusFilter.val)];
  mergedManagersFilter.val = [...new Set(mergedManagersFilter.val)];

  // Add all non-status and non-managers filters and the merged filters (if applicable)
  return [
    ...result.filter((filter) => !((filter.key === 'status' || filter.key === 'managers') && filter.op === 'in')),
    ...(mergedStatusFilter.val.length
      ? [mergedStatusFilter]
      : filters.bookmarks.length
      ? []
      : [{ key: 'status', val: ['teapot_status'], op: 'in' }]),
    ...(mergedManagersFilter.val.length ? [mergedManagersFilter] : [])
  ];
};

// Helper function to check if a value is valid (excluding undefined, null, empty strings, and empty arrays)
const isValidValue = (value) => {
  if (Array.isArray(value)) {
    return value.length > 0; // Exclure les tableaux vides
  }
  return value !== undefined && value !== null && value !== '';
};

// Function to transform [{ key: "key", value: "value", op: "op" }, ...] back to { key: value, ... }
export const transformNewSystemToOld = ({ filters }) => {
  if (!Array.isArray(filters)) return filters;

  const result = {};

  filters.forEach(({ key, val, op }) => {
    // Si la valeur est un tableau et l'opérateur est 'in', transformer en objets avec _id
    if (Array.isArray(val) && op === 'in' && val.every((id) => isValidObjectId(id))) {
      result[key] = val.map((id) => ({ _id: id }));
    } else {
      // Sinon, assigner la valeur directement
      result[key] = val;
    }
  });

  return result;
};

// Create a reusable regex for ObjectID validation
const objectIdRegex = /^[0-9a-fA-F]{24}$/;
const isValidObjectId = (id) => typeof id === 'string' && objectIdRegex.test(id);
